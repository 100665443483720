import {    
  MDBContainer,
  MDBSpinner} from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";
import { fetch_get, fetch_post } from '../../../services/common';
import { useEffect, useRef } from 'react';
import { useLoaderData } from "react-router-dom";

export async function loader({ params }) {

	return params.key_id;
}

async function capturePayment(order_key){
	
	if(order_key){
		const order = await fetch_get("app/pay/data/" + order_key);

		//Check if license has been added for example
		const payment_logic_done = () => {
			let result = true;
			switch(order.data.type){
				case 'License':
					const license_array = JSON.parse(order.data.metadata);
					license_array.map(license => {
						if(license.user_license_id < 0){
							result = false;
						}
					})
					break;
				default:
					fetch_post("app/pay/do_submit/" + order.data.key_id, {action: "add_edit", db_error_msg: "Okänd ordertyp."});
					break;
			}
			if(!result){
				fetch_post("app/pay/do_submit/" + order.data.key_id, {action: "add_edit", db_error_msg: "Licens kunde inte läggas till, var god kontakta support."});
			}
			return result
		}

		const obj = await fetch_get("app/swedbankpay/swedbank/" + order.data.payment_key + "?$expand=paid");

		if(!obj.data.paymentOrder.status === "Paid"){
			fetch_post("app/pay/do_submit/" + order.data.key_id, {action: "add_edit", db_error_msg: "Betalningen kunde inte genomföras."});
		}
		
		if(order.data.status === "Complete" && obj.data.paymentOrder.status === "Paid" && payment_logic_done()){
			return true
		} else {
			return false
		}
	} else {
		return false;
	}
	

}

export default function ProcessPayment() {

	const navigate = useNavigate();
	const order_key = useLoaderData();
	localStorage.removeItem('license-form')

	const initialized = useRef(false)

	useEffect(() => {
		if(!initialized.current){
			initialized.current = true
			capturePayment(order_key)
			.then((data) =>
				setTimeout(() => {
					if(data){
						navigate("/successful-payment/" + order_key);
					} else {
						navigate("/payment-error/" + order_key);
					}
				}, 1000)
			);
		}
	}, [])
  
      //
   return (
    <MDBContainer className="py-4">
        <div className='d-flex justify-content-center'>
          <MDBSpinner role='status'>
            <span className='visually-hidden'>Loading...</span>
          </MDBSpinner>
        </div>
        <div className='d-flex justify-content-center'>
          Behandlar betalning, stäng inte denna sida.
        </div>
    </MDBContainer>
   );
}