import React,{ useMemo, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,
	MDBIcon,
	MDBListGroup,
	MDBListGroupItem,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBBtn,
	MDBBtnGroup
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box,
  ListItemIcon,
  MenuItem,
} from '@mui/material';
import { fetch_get } from "../../../../services/common";
import AssociationRolesModal from './AssociationRolesModal';

export default function AssociationUsers() {

	const loadObject = useLoaderData();
	const association_id = loadObject.association.data.id;
	const [association, setAssociation] = useState(null)
	const roleList = ["Föreningsadministratör", "Ordförande", "Kassör", "Sekreterare"]
	const [columnFilters, setColumnFilters] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null)
	const [refreshTable, setRefreshTable] = useState(false)
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
	const [addRoleModal, setAddRoleModal] = useState(false);

	const toggleRoleModal = (user) => {
		setSelectedUser(user)
		setAddRoleModal(!addRoleModal)
	};

	useEffect(() => {
		fetch_get("app/forening/all/" + association_id)
		.then((data) =>
			setTimeout(() => {
				setAssociation(data.data);
			}, 3000)
		);
		setRefreshTable(!refreshTable)
  }, [addRoleModal]);

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'first_name',
        header: 'Förnamn',
        size: 150,
				/* Cell: ({renderedCellValue,row}) => (
					<a href={"/user/" + row.original.user_key_id + "/profile"}>
						{renderedCellValue}
					</a>
				) */
      },
			{
        accessorKey: 'last_name',
        header: 'Efternamn',
        size: 150,
				/* Cell: ({renderedCellValue,row}) => (
					<a href={"/user/" + row.original.user_key_id + "/profile"}>
						{renderedCellValue}
					</a>
				) */
      },
			{
        accessorKey: 'birth_date',
        header: 'Födelsedatum',
        size: 50,
      },
			{
        accessorKey: 'iid_nr',
        header: 'IID-nr',
        size: 50,
      },
			{
        accessorKey: 'email',
        header: 'E-post',
        size: 150,
				sortUndefined: 'first'
      },
			{
				accessorFn: (originalRow) => (originalRow.roles !== null ? 'true' : 'false'),
				id: 'roles',
				header: 'Roller',
				size: 50,
				filterVariant: 'multi-select',
				filterSelectOptions: roleList,
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' && association ? 
							<MDBPopover
								dismiss
								tag='a'
								style={{ cursor: 'pointer',color: '#0065A4' }}
								placement='left'
								btnChildren={<MDBIcon fas icon="user-tie" />}
							>
								<MDBPopoverHeader>Roller</MDBPopoverHeader>
								<MDBPopoverBody>
									{listRoles(row.original.user_key_id)}
								</MDBPopoverBody>
							</MDBPopover> : null}
							<MDBBtn data-bs-toggle="tooltip" onClick={() => toggleRoleModal(row.original)} data-bs-placement="top" title="Hantera roller" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
								<MDBIcon className='py-0' fas icon='cog'/>
							</MDBBtn>
					</>
				)
			},
    ],
    [association],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
			refreshTable
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/forening_users/',
        window.location.protocol + "//" + window.location.hostname,
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function filterFunction(){
				let filterString = "forening_id=" + association_id
				if(globalFilter && globalFilter.length > 0 ){
					filterString = filterString.concat(" AND (first_name LIKE '%" + 
						globalFilter + "%' OR last_name LIKE '%" + 
						globalFilter + "%' OR user_fullname LIKE '%" + 
						globalFilter + "%' OR birth_date LIKE '%" + 
						globalFilter + "%' OR iid_nr LIKE '%" +
						globalFilter + "%' OR email LIKE '%" +
						globalFilter + "%')")
				}
				if(columnFilters.length > 0){
					filterString = filterString.concat(" AND (")
					columnFilters.map((col, index) => {
						let searchParam = " LIKE '%" + col.value + "%'"
						if(col.id === "roles"){
							let roleFilter = ""
							col.value.map((desc,index) => {
								if(index == 0){
									roleFilter = "(roles LIKE " + "'%" + desc + "%'"
								} else {
									roleFilter = roleFilter.concat(" OR roles LIKE '%" + desc + "%'")
								}
							})
							roleFilter = roleFilter.concat(")")
							filterString = filterString.concat(roleFilter)
						} else {
							if(index == 0){
								filterString = filterString.concat(col.id + searchParam)
							} else {
								filterString = filterString.concat(" AND " + col.id + searchParam)
							}
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
      fetchURL.searchParams.set('_filter', filterFunction());
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}

      const resp = await fetch_get(fetchURL.href.split(window.location.hostname).pop())
      return resp;
    },
    placeholderData: keepPreviousData,
  });

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true,
			columnPinning: {
        right: ['mrt-row-actions'],
      },
		},
		/* enableRowActions: true, */
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
		enableStickyFooter: true,
		muiTableContainerProps: { sx: { maxHeight: window.innerHeight*0.65 } },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
		/* renderRowActionMenuItems: ({ row, closeMenu }) => [
			<MDBBtnGroup key={row.id} shadow='0' vertical aria-label='Vertical button group'>
				<MDBBtn onClick={() => (closeMenu(), openSkyttekortModal(row.original))} outline  color='secondary'>Lägg till skyttekort</MDBBtn>
			</MDBBtnGroup>
			,
		], */
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableColumnActions: false,
		enableFullScreenToggle: false,
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting
    },
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
        </Box>

      );
		}
  });

	function listRoles(key_id){
		return <MDBListGroup style={{ minWidth: '22rem' }} light small>
						{association.role_list.map((role,i) => {
							if(role.user_key_id === key_id){
								return <MDBListGroupItem key={key_id + i}>{role.role_description}</MDBListGroupItem>
							}
						})}
					</MDBListGroup>
	}

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Medlemmar</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
				</MDBRow>
				{(selectedUser !== null && association && addRoleModal) ? <AssociationRolesModal association={association} sentUser={[selectedUser]} show={addRoleModal} close={() => setAddRoleModal(false)}/> : null}
			</MDBContainer>
		</>
          );
}