import { useNavigate, useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
  MDBBtn,
  MDBTable, 
  MDBTableHead, 
  MDBTableBody,
  MDBBadge,
	MDBSwitch,
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBAlert,
	MDBPopover,
	MDBPopoverBody,
	MDBPopoverHeader,
	MDBIcon
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
 import {
	 MaterialReactTable,
	 useMaterialReactTable,
 } from 'material-react-table';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";
import AddDotAmount from "../payment/utils/AddDotAmount";

export async function loader({ params }) {

	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	let user = null;
	if(params.key_id === decoded.kid){
		user = await fetch_get("app/user/all/" + params.key_id);

		let isParent = false;
		user.data.parents.map(parent => {
			const loggedInParent = localStorage.getItem("key_id")
			if(parent.key_id == loggedInParent){
				isParent = true
			}
		})
	
		const obj = {
			user: user,
			guest: decoded.kid == params.key_id ? false : true,
			parent: isParent
		}
		return obj
	}

	/* 

	if(!obj.parent && obj.guest){
		if(!window.location.href.endsWith("/profile")){
			return <Navigate to="/unauthorized" replace />;
		}
	} */

	return null;
	
	
}

export default function UserLicense(){

	const loadObject = useLoaderData();
	const user = loadObject.user.data;
	const [loading, setLoading] = useState(true)
	const [tableData, setTableData] = useState([]); 

	useEffect(() => {
		setLoading(true)
		fetch_get("app/user/orders/" + user.key_id)
		.then((data) =>
			setTimeout(() => {
				const filterData = data.data.filter((row) => (row.status == "Reversed" || row.status == "Complete"));
				setTableData(filterData);
				setLoading(false)
			}, 3000)
		);

  }, []);

	function padDigits(num) {
		return num.toString().padStart(2, '0');
	}
	
	function newDate(date) {
		
		return [
			date.getFullYear(),
			padDigits(date.getMonth() + 1),
			padDigits(date.getDate()),
		].join('-');
	}

	function getStatusBadge(order){
		let icon,text,color;
		switch(order){
			case "Complete":
				icon = "check"
				text = "Betald"
				color = "success"
				break;
			case "Paid":
				icon = "check"
				text = "Betald"
				color = "success"
				break;
			case "Initialized":
				icon = "hourglass-start"
				text = "Betalning pågår"
				color = "warning"
				break;
			case "Aborted":
				icon = "times"
				text = "Avbrutet"
				color = "danger"
				break;
			case "Capturing":
				icon = "hourglass-start"
				text = "Tar emot pengar"
				color = "warning"
				break;
			case "Reversed":
				icon = "redo"
				text = "Återbetald"
				color = "success"
				break;
			case "DEFAULT":
				icon = "question"
				text = "Okänd status"
				color = "warning"
		}
		return <>
						<MDBPopover
							poperStyle={{width:150}}
							dismiss
							tag='a'
							style={{ cursor: 'pointer'}}
							placement='left'
							btnChildren={<MDBBadge className="mx-2" pill color={color} light><MDBIcon fas icon={icon} /></MDBBadge>}
						>
						<MDBPopoverBody>
							<b>{text}</b>
						</MDBPopoverBody>
						</MDBPopover>
					</>
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'add_date',
        header: 'Betalningsdatum',
        size: 100,
				Cell: ({ cell }) => (
					<>{newDate(new Date(cell.getValue()))}</>
						
				)
      },
			{
        accessorKey: 'description',
        header: 'Produkt',
        size: 100,
      },
      {
        accessorKey: 'order_ref',
        header: 'Ordernummer',
        size: 50,
      },
			{
        accessorKey: 'reciever_name', 
        header: 'Betalare',
        size: 100,
      },
			{
        accessorKey: 'payee_name', 
        header: 'Betalningsmottagare',
        size: 50,
      },
			{
        accessorKey: 'payment_method', 
        header: 'Betalningsmetod',
        size: 50,
      },
			{
        accessorKey: 'amount',
        header: 'Belopp',
        size: 50,
				Cell: ({ cell }) => (
					<>{AddDotAmount(cell.getValue())}kr</>
						
				)
      },
			{
				accessorFn: (originalRow) => (originalRow.status),
				id: 'status',
				header: 'Status',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell,row }) => (
					getStatusBadge(cell.getValue())
				)
			},
			
    ],
    [],
  );
	
	const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData,
		initialState: { 
			columnPinning: {
        right: ['mrt-row-actions'],
      },
		},
		state: {
			isLoading: loading
		},
    enableColumnFilters: false,
		enableColumnActions: false,
		enableDensityToggle: false,
		enableFullScreenToggle: false,
		enableHiding: false,
		enableFilters: false,
		enableStickyFooter: true,
		muiTableContainerProps: { sx: { maxHeight: window.innerHeight*0.65 } },
		localization:MRT_Localization_SV,
		enableRowActions: true,
		renderRowActions: ({ row }) => (
			row.original.status === "Complete" || row.original.status === "Reversed" ? 
				<MDBBtn data-bs-toggle="tooltip" href={window.location.protocol + "//" + window.location.hostname + "/api/app/swedbankpay/download-receipt/" + row.original.key_id} data-bs-placement="top" title="Ladda ner kvitto" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
					<MDBIcon className='py-0' far icon='file-pdf'/>
				</MDBBtn> : null
		)
  });
  
    return (
			<>
				<MDBContainer className="py-4">
					<h3>Mina köp</h3>
					<hr/>
					<MDBRow className='d-flex justify-content-center'>
						<MDBCol md="12" className="mb-3">
							<BorderCol headline="Mina köp" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
								<MaterialReactTable table={table} />
							</BorderCol>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</>
		);

 
}