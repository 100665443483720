import React,{ useMemo, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,
	MDBIcon,
	MDBBadge,
	MDBCardText,
	MDBCard,
	MDBListGroup,
	MDBListGroupItem,
	MDBBtn
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box
} from '@mui/material';
import {useNavigate } from "react-router-dom";
import { fetch_get } from '../../../../services/common';

export default function AssociationLicenses() {

	const navigate = useNavigate();
	const loadObject = useLoaderData();
	const association_id = loadObject.association.data.id;
	const sportList = loadObject.sportList.data;
	const [columnFilters, setColumnFilters] = useState([]);
	const [selectedRows, setSelectedRows] = useState([])
	const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('');
	const [loading, setLoading] = useState(false)
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
	localStorage.removeItem('license-form')

	function listSports(sportString){
		const sportArr = sportString.split(',');
		return sportArr.map(sportID => {
			const sportText = sportList.find(({ value }) => value === sportID)
			if(sportText){
				return <MDBCardText key={sportText.value} className="text-muted">{sportText.text}</MDBCardText>
			}
		})
	}

	function allowToBuy(row){
		const earliestBuyDate = new Date(new Date().getFullYear(),new Date().getMonth()+1,new Date().getDate()).getTime();
		const currentLatestDate = new Date(row.original.lic_expired_date).getTime();
		if(row.original.skyttekort_grenar !== null && earliestBuyDate > currentLatestDate){
			return true
		} else {
			return false
		}
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'first_name',
        header: 'Förnamn',
        size: 150,
				/* Cell: ({renderedCellValue,row}) => (
					<a href={"/user/" + row.original.user_key_id + "/profile"}>
						{renderedCellValue}
					</a>
				) */
      },
			{
        accessorKey: 'last_name',
        header: 'Efternamn',
        size: 150,
				/* Cell: ({renderedCellValue,row}) => (
					<a href={"/user/" + row.original.user_key_id + "/profile"}>
						{renderedCellValue}
					</a>
				) */
      },
			{
        accessorKey: 'birth_date',
        header: 'Födelsedatum',
        size: 50,
      },
			{
        accessorKey: 'iid_nr',
        header: 'IID-nr',
        size: 50,
      },
			{
				accessorFn: (originalRow) => (originalRow.skyttekort_grenar !== null ? 'true' : 'false'),
				id: 'skyttekort_grenar',
				header: 'Skyttekort',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' ? 
							<MDBPopover
								poperStyle={{width:150}}
								dismiss
								tag='a'
								style={{ cursor: 'pointer'}}
								placement='left'
								btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
							>
							<MDBPopoverHeader>Grenar</MDBPopoverHeader>
							<MDBPopoverBody>
								{row.original.skyttekort_grenar ? listSports(row.original.skyttekort_grenar) : null}
							</MDBPopoverBody>
							</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>}
					</>
				)
			},
			{
				accessorFn: (originalRow) => (originalRow.active_license === 'true' ? 'true' : 'false'),
				id: 'active_license',
				header: 'Tävlingslicens',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'true' ? 
							<MDBPopover
								poperStyle={{width:150}}
								dismiss
								tag='a'
								style={{ cursor: 'pointer'}}
								placement='left'
								btnChildren={<MDBBadge className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge>}
							>
							<MDBPopoverHeader>Grenar</MDBPopoverHeader>
							<MDBPopoverBody>
								{row.original.skyttekort_grenar ? listSports(row.original.skyttekort_grenar) : null}
							</MDBPopoverBody>
							</MDBPopover> : <MDBBadge className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>}
					</>
				)
			},
			{
        accessorKey: 'lic_expired_date',
        header: 'Giltig tom.',
        size: 50
      }
    ],
    [],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/forening_users_lic_kort/',
        window.location.protocol + "//" + window.location.hostname,
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function filterFunction(){
				let filterString = "forening_id=" + association_id
				if(globalFilter && globalFilter.length > 0 ){
					filterString = filterString.concat(" AND (first_name LIKE '%" + 
						globalFilter + "%' OR last_name LIKE '%" + 
						globalFilter + "%' OR user_fullname LIKE '%" + 
						globalFilter + "%' OR birth_date LIKE '%" + 
						globalFilter + "%' OR iid_nr LIKE '%" +
						globalFilter + "%')")
				}
				if(columnFilters.length > 0){
					filterString = filterString.concat(" AND (")
					columnFilters.map((col, index) => {
						let searchParam = " LIKE '%" + col.value + "%'"
						if(col.id === "skyttekort_grenar"){
							if(col.value === "true"){
								if(index == 0){
									filterString = filterString.concat(col.id + " IS NOT NULL")
								} else {
									filterString = filterString.concat(" AND " + col.id + " IS NOT NULL")
								}
							} else {
								if(index == 0){
									filterString = filterString.concat(col.id + " IS NULL")
								} else {
									filterString = filterString.concat(" AND " + col.id + " IS NULL")
								}
							}
						} else {
							if(index == 0){
								filterString = filterString.concat(col.id + searchParam)
							} else {
								filterString = filterString.concat(" AND " + col.id + searchParam)
							}
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
      fetchURL.searchParams.set('_filter', filterFunction());
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}

			const resp = await fetch_get(fetchURL.href.split(window.location.hostname).pop())
      return resp;
    },
    placeholderData: keepPreviousData,
  });

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true,
			columnPinning: {
        right: ['mrt-row-actions'],
      },
		},
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
		enableStickyFooter: true,
		muiTableContainerProps: { sx: { maxHeight: window.innerHeight*0.65 } },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableRowSelection: (row) => allowToBuy(row),
		onRowSelectionChange: setRowSelection,
		selectAllMode: 'all',
		enableCellActions: true,
    muiSelectCheckboxProps: { color: 'secondary' },
		enableColumnActions: false,
		enableFullScreenToggle: false,
		getRowId: (originalRow) => originalRow.user_key_id + " : " + originalRow.first_name + " " + originalRow.last_name + " (" + originalRow.iid_nr + ")",
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
			rowSelection
    },
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
						<MDBBtn disabled={!(selectedRows.length > 0)} className='mx-2' color='tertiary' rippleColor='light' onClick={() => table.resetRowSelection()}>Rensa val</MDBBtn>
          </Box>
        </Box>

      );
		}
  });

	useEffect(() => {
		let rowArr = [];
		for (const [key, value] of Object.entries(rowSelection)) {
			const selectString = `${key}`;
			const userArr = selectString.split(" : ");
			const userObj = {key_id: userArr[0], user_name: userArr[1]}
			rowArr.push(userObj)
		}
		setSelectedRows(rowArr)
	}, [rowSelection]);

	function listSelectedUsers(){
		if(selectedRows){
			return selectedRows.map(row => {
				return 	<MDBListGroupItem key={row.key_id}>
									<div>
										<div className='fw-bold'>{row.user_name}</div>
									</div>
								</MDBListGroupItem>
			})
		}
	}



	return (
		<>
			<MDBContainer className="py-4">
				<h3>Medlemmar</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="10" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
					<MDBCol size="2" className="mb-3">
						<MDBCard>
							<MDBListGroup>
								{listSelectedUsers()}
							</MDBListGroup>
							<MDBBtn disabled={selectedRows.length === 0 || loading ? true : false} onClick={() => {navigate("/step-license",{state: {selectedRows, association_id}})}}>Köp tävlingslicens</MDBBtn>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
          );
}