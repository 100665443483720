import { useLoaderData } from "react-router-dom";
import React, { useState} from 'react';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { 
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardImage,
	MDBCardBody,
	MDBTypography,
	MDBCardText,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,   
  MDBContainer,
	MDBIcon,
	MDBBtn,
	MDBBadge,
	MDBCardHeader,
	MDBCardTitle
 } from 'mdb-react-ui-kit';
import { BorderCol } from "../../modules/BorderCol";
import GetActiveLicense from "../license/utils/GetActiveLicense";
import GetSkyttekortStatus from "../skyttekort/utils/GetSkyttekortStatus";
import { GetLatestStartDate, GetValidThru, newDate } from "../license/utils/GetLicenseDates";
import Icon_Svsf from "../../svg/svg_icon_svsf";


export async function loader({ params }) {

	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	let user = null;
	if(params.key_id === decoded.kid){
		user = await fetch_get("app/user/all/" + params.key_id);

		let isParent = false;
		user.data.parents.map(parent => {
			const loggedInParent = localStorage.getItem("key_id")
			if(parent.key_id == loggedInParent){
				isParent = true
			}
		})
	
		const obj = {
			user: user,
			guest: decoded.kid == params.key_id ? false : true,
			parent: isParent
		}
		return obj
	}

	/* 

	if(!obj.parent && obj.guest){
		if(!window.location.href.endsWith("/profile")){
			return <Navigate to="/unauthorized" replace />;
		}
	} */

	return null;
	
	
}

export default function UserDetails() {

	const loadObject = useLoaderData();
  const user = loadObject.user.data;
	const currentDate = newDate(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
  
  return (
		<>
			<MDBContainer className="py-4 p-3 bg-light bg-gradient rounded-5">
				<h3>Medlemskort</h3>
				<hr/>
				<MDBRow className="justify-content-center align-items-center h-100">
					<MDBCol md={6}>
						<MDBCard shadow='0' border='dark' background='white'>
							
							<MDBCardBody className='text-dark'>
								<MDBRow className="pb-3">
									<MDBCol className="text-center" md={2}><Icon_Svsf/></MDBCol>
									<MDBCol md={8}><MDBCardTitle className="text-center mb-0">Medlemskort utfärdat av</MDBCardTitle><MDBCardTitle className="text-center">Svenska Skyttesportförbundet</MDBCardTitle></MDBCol>
									<MDBCol className="text-end" md={2}>
										<MDBBtn data-bs-toggle="tooltip" href={window.location.protocol + "//" + window.location.hostname + "/api/app/user/download-membercard/" + user.key_id} data-bs-placement="top" title="Ladda ner medlemskort" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
											<MDBIcon size="lg" className='py-0' far icon='file-pdf'/>
										</MDBBtn>
									</MDBCol>
								</MDBRow>
								<MDBCardText>
									<h5>{user.first_name} {user.last_name}</h5>
									<MDBRow>
										<MDBCol md={6}>
											<MDBRow>
												<MDBCol><b>Efternamn: </b></MDBCol><MDBCol>{user.last_name}</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol><b>Förnamn: </b></MDBCol><MDBCol>{user.first_name}</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol><b>Förening: </b></MDBCol>
												<MDBCol>{user.foreningar.length == 0 ? <span> Saknas</span> :
														user.foreningar.map(forening => {
															return <div key={forening.id}>{forening.name}</div>
														})
													}
												</MDBCol>
											</MDBRow>
										</MDBCol>
										<MDBCol md={6}>
											<MDBRow>
												<MDBCol><b>IID-nr: </b></MDBCol><MDBCol>{user.licens_nr}</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol><b>Födelsedatum: </b></MDBCol><MDBCol>{user.birth_date}</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol><b>Skyttekort: </b></MDBCol>
												<MDBCol>{user.skyttekort.length == 0 ? <span> Saknas</span> :
														user.skyttekort.map(skyttekort => {
															return <div key={skyttekort.id}>{skyttekort.gren_name}</div>
														})
													}
												</MDBCol>
											</MDBRow>
											<MDBRow>
												<MDBCol><b>Licens giltlig tom.: </b></MDBCol>
												<MDBCol>{user.licenser.length == 0 ? <span> Saknas</span> :
														<>{GetValidThru(user.licenser) == currentDate ? "-" : GetValidThru(user.licenser)}</>
													}
												</MDBCol>
											</MDBRow>
										</MDBCol>
									</MDBRow>
									
								</MDBCardText>
							</MDBCardBody>
						</MDBCard>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
  );
}