import React,{ useMemo } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";

export async function loader({ params }) {

	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	let user = null;
	if(params.key_id === decoded.kid){
		user = await fetch_get("app/user/all/" + params.key_id);

		let isParent = false;
		user.data.parents.map(parent => {
			const loggedInParent = localStorage.getItem("key_id")
			if(parent.key_id == loggedInParent){
				isParent = true
			}
		})
	
		const obj = {
			user: user,
			guest: decoded.kid == params.key_id ? false : true,
			parent: isParent
		}
		return obj
	}

	/* 

	if(!obj.parent && obj.guest){
		if(!window.location.href.endsWith("/profile")){
			return <Navigate to="/unauthorized" replace />;
		}
	} */

	return null;
	
	
}

export default function UserFamily(params){

	const loadObject = useLoaderData();
  const childs = loadObject.user.data.childs;

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'first_name', //access nested data with dot notation
        header: 'Förnamn',
        size: 150,
				Cell: ({row}) => (
					<a href={"/user/" + row.original.key_id + "/profile"}>
						{row.original.first_name}
					</a>
				)
      },
			{
        accessorKey: 'last_name', //access nested data with dot notation
        header: 'Efternamn',
        size: 150,
				Cell: ({row}) => (
					<a href={"/user/" + row.original.key_id + "/profile"}>
						{row.original.last_name}
					</a>
				)
      },
			{
        accessorKey: 'birth_date', //access nested data with dot notation
        header: 'Födelsedatum',
        size: 50,
      },
			{
        accessorKey: 'licens_nr', //access nested data with dot notation
        header: 'IID-nr',
        size: 50,
      },
			{
        accessorKey: 'email', //access nested data with dot notation
        header: 'E-post',
        size: 150,
      },
			{
        accessorKey: 'cell_phone', //access nested data with dot notation
        header: 'Telefonnummer',
        size: 50,
      },
    ],
    [childs],
  );
  
	const table = useMaterialReactTable({
    columns: tableColumns,
    data: childs,
    enableColumnFilters: false,
		enableColumnActions: false,
		enableTopToolbar:false,
		enablePagination:false,
		enableStickyFooter: true,
		muiTableContainerProps: { sx: { maxHeight: window.innerHeight*0.65 } },
		localization:MRT_Localization_SV
  });

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Familj</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<BorderCol headline="Mina barn" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
						<MaterialReactTable table={table} />
						</BorderCol>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
          );

}