import React, { useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBCheckbox,
  MDBInput,
  MDBBtn,
  MDBAlert,
	MDBIcon,
	MDBInputGroup,
} from "mdb-react-ui-kit";
import {
  //Outlet,
  //NavLink,
  Link,
  Navigate,
  redirect,
  //useLoaderData,
  //Form,
  //redirect,
  //useNavigation,
  //useSubmit,
} from "react-router-dom";
import AuthService from "../../../services/auth.service";
import jwt_decode from "jwt-decode";

const LoginRegister = () => {
  const [username, setUsername] = useState(() => {
    const saved = localStorage.getItem("user");
    return saved || "";
  });

  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
	const [openError, setOpenError] = useState(false);
  const [ready, setReady] = useState(false);
	const [showPassword, setShowPassword] = useState(false)
  const [message, setMessage] = useState("");

  const [fillActive, setFillActive] = useState("tab-login");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    setMessage("");
		setOpenError(false)
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setMessage("");
		setOpenError(false)
  };

  const handleFillClick = (value) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!username) {
      setMessage('Felaktigt IdrottID eller lösenord!');
			setOpenError(true)
      return;
    }
    AuthService.login(username, password).then(
      (data) => {
        localStorage.setItem("user", username);
        localStorage.setItem("key_id", data.key_id);
        localStorage.setItem("token", data.token);

        const decoded = jwt_decode(data.token);
        
        window.location.replace("/");
        setReady(true);

      },
      (error) => {
        setMessage(error.message);
				setOpenError(true)
      }
    );
  };

  return (

    <MDBContainer className="pt-5">
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="6">
          <MDBCard>
            <MDBCardBody className="p-4">
							<h5>Inloggning</h5>
							<form onSubmit={handleLogin}>
								<MDBInput
									className="mb-4"
									type="text"
									id="loginName"
									label="Personnummer, IID-nummer (IdrottsID) eller E-post"
									value={username}
									required
									onChange={onChangeUsername}
								/>

								<MDBInputGroup className="mb-4">
									<input required value={password} onChange={onChangePassword} className="form-control" placeholder="Lösenord" type={showPassword ? 'text' : 'password'} />
									<MDBBtn data-bs-toggle="tooltip" data-bs-placement="top" title={showPassword ? 'Dölj lösenord' : 'Visa lösenord'} onClick={() => setShowPassword(!showPassword)} className="mx-0 px-1" type="button" outline style={{ color: '#0065A4' }}><MDBIcon fas icon={showPassword ? 'eye' : 'eye-slash'} size='lg' /></MDBBtn>
								</MDBInputGroup>

								<MDBRow className="mb-4">
									<MDBCol md="4" className="d-flex justify-content-center">
										<MDBCheckbox
											className=" mb-3 mb-md-0"
											defaultChecked
											label=" Kom ihåg mig"
										/>
									</MDBCol>

									<MDBCol md="8" className="d-flex justify-content-center">
										<Link className="link" as={Link} to="/forgot-password">Glömt ditt lösenord eller loggar in för första gången?</Link>
									</MDBCol>
								</MDBRow>

								<MDBAlert open={openError} className='mt-3 w-100' color='danger'>
									{message}
								</MDBAlert>

								<MDBBtn type="submit" block className="mb-4">
									Logga in
								</MDBBtn>
								
							</form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default LoginRegister;
