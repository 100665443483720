import React, { useEffect,useState, useMemo } from 'react';
import {    
  MDBBtn,
	MDBRow,
	MDBSpinner,
	MDBIcon,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBAlert,
	MDBBadge,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBCol,
	MDBSelect,
	MDBCheckbox,
	MDBValidation,
	MDBValidationItem,
	MDBDatepicker,
	MDBInput
 } from 'mdb-react-ui-kit';
 import {
	MRT_Table,
	useMaterialReactTable,
} from 'material-react-table';
 import { fetch_get,fetch_post } from '../../../services/common';
 import { EditStatusLicense } from './service/EditStatusLicense';
import { Box } from '@mui/material';
import { AddLicense } from './service/AddLicense';
import { GetLatestStartDate, GetEndDate, GetAllowedToBuy } from './utils/GetLicenseDates';
import GetSkyttekortStatus from '../skyttekort/utils/GetSkyttekortStatus';
import CompareDates from './utils/CompareDates';
import AuthService from '../../../services/auth.service';

export default function EditLicenseModal(params){
	
	const [loading, setLoading] = useState(false);
	const sentUser = params.sentUser;
	const [licenseEdited, setLicenseEdited] = useState(false)
	const [licenseAdded, setLicenseAdded] = useState(false)
	const [licenseError, setLicenseError] = useState(false)
	const [licensesUser, setLicensesUser] = useState([])
	const [user, setUser] = useState(null)
	const [valid, setValid] = useState(false)
	const [changeMade, setChangeMade] = useState(false)
	const [licenses, setLicenses] = useState(null)
	const [sportList, setSportList] = useState([]);
	const [checked, setChecked] = useState(false);
	const [skyttekortSportgren, setSkyttekortSportgren] = useState(null)
	const ageGroup = (new Date().getFullYear() - new Date(sentUser.birth_date).getFullYear() > 21) ? "S" : "J"
	const [formValue, setFormValue] = useState({
    price: '',
		payment_method: '',
		date: '',
		sportgren_id: 0
  });

	const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

	function getBadge(status){
		if(status === "ACTIVE"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Aktiv" className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge> 
		}
		if(status === "DELETED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Raderad" className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>
		}
		if(status === "PENDING"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Förestående" className="mx-2" pill color='warning' light><MDBIcon far icon="clock" /></MDBBadge>
		}
		if(status === "INACTIVE"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Utången" className="mx-2" pill color='warning' light><MDBIcon far icon="calendar-check" /></MDBBadge>
		}
		if(status === "LOCKED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Spärrad" className="mx-2" pill color='danger' light><MDBIcon fas icon="ban" /></MDBBadge>
		}
		if(status === "PAUSED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Spärrad" className="mx-2" pill color='warning' light><MDBIcon fas icon="pause" /></MDBBadge>
		}
		if(status === "EXPIRED"){
			return <MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Utången" className="mx-2" pill color='warning' light><MDBIcon far icon="calendar-check" /></MDBBadge>
		}
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'lic_name',
        header: 'Tävlingslicens',
        size: 200
      },
			{
        accessorKey: 'start_date',
        header: 'Startdatum',
        size: 50
      },
			{
        accessorKey: 'end_date',
        header: 'Slutdatum',
        size: 50
      },
			{
        accessorKey: 'gren_name',
        header: 'Idrottskommitté',
        size: 50
      },
			{
        accessorKey: 'status',
        header: 'Status',
        size: 50,
				Cell: ({ cell }) => (
					<>
						{getBadge(cell.getValue())}						
					</>
				)
      },
    ],
    [],
  );

	const addLicense = async () => {
		if(checked){
			const addedLicense = await AddLicense(user.key_id, formValue.date, specialEndDate(user.licenser),formValue.sportgren_id,ageGroup === "S" ? 4 : 7 );
			const metaData = {license: ageGroup === "S" ? licenses.find((license) => license.id == 4) : licenses.find((license) => license.id == 7),
				sport: formValue.sportgren_id,
				startDate: GetLatestStartDate(user.licenser),
				endDate: specialEndDate(user.licenser),
				price: formValue.price,
				age: (new Date().getFullYear() - new Date(user.birth_date).getFullYear() > 21) ? "Senior" : "Junior",
				userID: user.id,
				name: user.first_name + " " + user.last_name,
				user_license_id: addedLicense.id,
				user_key: user.key_id,
				licenseStatus: CompareDates(GetLatestStartDate(user.licenser), GetEndDate(user.licenser))}
			const order = await fetch_post("app/pay/do_submit/new", {
				"action":"add_edit", 
				"db_user_id" : user.id,
				"db_payment_method": formValue.payment_method,
				"db_operation" : "Purchase",
				"db_amount": formValue.price + "00",
				"db_vat_amount": (formValue.price*0.25) + "00",
				"db_remaining_capture_amount": 0,
				"db_description" : "Licensköp",
				"db_type" : "License",
				"db_payee_name": "SkytteTA",
				"db_status": "Complete",
				"db_metadata" : JSON.stringify([metaData]),
				"db_added_manually" : 1,
				"db_pay_date": formValue.date});
			fetch_post("app/pay/do_submit/" + order.msg.key_id, {
				"action": "add_edit",
				"db_payee_ref": order.id,
				"db_order_ref": "LIC-" + order.id
			})
			const orderItem = await fetch_post("app/pay/do_submit/" + order.msg.key_id, {
				"action":"add_edit_items", 
				"order_items": [{
				"db_reference" : "O" + order.id + "U" + user.id,
				"db_name" : "License_" + metaData.license.id,
				"db_amount": formValue.price + "00",
				"db_vat_amount": (formValue.price*0.25) + "00",
				"db_description" : metaData.license.name,
				"db_type" : "SERVICE",
				"db_class" : "License",
				"db_unit_price": formValue.price + "00",
				"db_quantity": 1,
				"db_reciever_user_id" : user.id,
				"db_product_id": addedLicense.id}]});
			if(addedLicense.status === "OK" && orderItem.status === "OK"){
				setLicenseAdded((prev) => !prev)
				setChangeMade(!changeMade)
			} else {
				setLicenseError((prev) => !prev)
			}
		} else {
			if(user.licenser && formValue.sportgren_id){
				AddLicense(user.key_id, GetLatestStartDate(user.licenser), specialEndDate(user.licenser),formValue.sportgren_id,ageGroup === "S" ? 4 : 7 ).then(obj => {
					if(obj.status === "OK"){
						setLicenseAdded((prev) => !prev)
						setChangeMade(!changeMade)
					} else {
						setLicenseError((prev) => !prev)
					}
				});
			}
		}
		
	}

	function specialEndDate(licenses){
		const startDate = new Date(GetLatestStartDate(licenses))
		if(startDate.getFullYear() < 2025){
			return "2025-12-31";
		} else {
			return GetEndDate(licenses)
		}
	}
	

	const editLicenseStatus = (status,licenseID) => {
		EditStatusLicense(user.user_key_id,licenseID,status).then(obj => {
			if(obj.status === "OK"){
				setLicenseEdited((prev) => !prev);
				setChangeMade(!changeMade)
			} else {
				setLicenseError((prev) => !prev)
			}
		});
		
	}

	useEffect(() => {
		setLoading(true)
		fetch_get("app/user/all/" + sentUser.user_key_id).then((data) => {
			setTimeout(() => {
				setUser(data.data)
				let filterArr = []
				data.data.licenser.map(license => {
					if(license.status !== "DELETED"){
						filterArr.push(license)
					}
				})
				setLicensesUser(filterArr);
			}, 2000)
		})
		fetch_get("app/list/sportgren-skyttekort").then((data) => {
			setTimeout(() => {
				setSkyttekortSportgren(data.data)
			}, 2000)
		})
		fetch_get("app/list/licenser").then((data) => {
			setTimeout(() => {
				setLicenses(data.data)
			}, 2000)
		})
		fetch_get("app/list/lov-grenar").then((data) => {
			setTimeout(() => {
				if(data.data[0]){
					const sportArray = (name, id, formName, secondary) => { return { text: name, value: id, name: formName, secondaryText: secondary}};
					const sportRows = [{ text: '', hidden: true }];
					data.data.map(sport => {
						const selectSport = sportArray(sport.text, Number(sport.value), "sport", sport.secondaryText);
						sportRows.push(selectSport);
					},);
					setSportList(sportRows)
				} 
			}, setLoading(false), 2000)
		})
	}, [changeMade]);

	function filterSportList(skyttekortList){
		const skyttekortIdsArray = []
		skyttekortList.map(skyttekort => {
			if(skyttekort.status === "ACTIVE"){
				skyttekortIdsArray.push(skyttekort.def_skyttekort_id);
			}
		})
		const validSportgrenarArray = []
		skyttekortSportgren.map(sportgren => {
			skyttekortIdsArray.map(id => {
				if(sportgren.def_skyttekort_id == id){
					validSportgrenarArray.push(Number(sportgren.def_sportgrenar_id))
				}
			})
		})
		const filteredSportList = sportList.filter( function( element ) {
			return validSportgrenarArray.includes( element.value );
		})
		filteredSportList.unshift({ text: "", hidden: true })
		return filteredSportList
	}

	const table = useMaterialReactTable({
    columns: tableColumns,
    data: licensesUser,
		enableColumnActions: false,
		localization: {
			noRecordsToDisplay: "Licens saknas",
			actions: "Åtgärder"
		},
		initialState: { 
			columnPinning: {
        right: ['mrt-row-actions'],
      },
			
		},
		state: {
      isLoading: loading
    },
		enableRowActions: true,
		renderRowActions: ({ row }) => (
			row.original.status === "ACTIVE" || row.original.status === "PENDING" ?
			<Box>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Inaktivera licens" modal color='none' className='m-1' style={{ color: 'red', background: 'white', border: 'none' }} btnChildren={
					
						<MDBIcon far icon="trash-alt" size='lg' />
					} onConfirm={() => editLicenseStatus("DELETED", row.original.id)}>
					<MDBPopconfirmMessage>Ta bort licens?</MDBPopconfirmMessage>
				</MDBPopconfirm>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Spärra licens" modal color='none' className='m-1' style={{ color: 'black', background: 'white', border: 'none' }} btnChildren={
					
					<MDBIcon fas icon="ban" size='lg' />
				} onConfirm={() => editLicenseStatus("LOCKED", row.original.id)}>
				<MDBPopconfirmMessage>Spärra licens?</MDBPopconfirmMessage>
			</MDBPopconfirm>
			</Box> : row.original.status === "DISABLED" || row.original.status === "PAUSED" || row.original.status === "LOCKED" ? 
			<Box>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Aktivera licens" modal color='none' className='m-1' style={{ color: '#0065A4', background: 'white', border: 'none' }} btnChildren={
					
						<MDBIcon fas icon="plus" size='lg' />
					} onConfirm={() => editLicenseStatus("ACTIVE", row.original.id)}>
					<MDBPopconfirmMessage>Återaktivera licens?</MDBPopconfirmMessage>
				</MDBPopconfirm>
			</Box> : null
		)
  });

	useEffect(() => {
		
		if(formValue){
			if(checked){
				let formValid = true
				Object.values(formValue).map(val => {
					if(val.length == 0){
						formValid = false
					}
				})
				setValid(formValid)
			} else {
				if(formValue.sportgren_id != 0){
					setValid(true)
				}
			}
		}
	}, [formValue, checked]);

    return <>
				<MDBAlert
					color='primary'
					autohide
					position='top-right'
					delay={4000}
					appendToBody
					open={licenseEdited}
					onClose={() => setLicenseEdited(false)}
				>
					Licens har ändrats.
				</MDBAlert>
				{user ? <MDBAlert
					color='primary'
					autohide
					position='top-right'
					delay={4000}
					appendToBody
					open={licenseAdded}
					onClose={() => setLicenseAdded(false)}
				>
					Licens har lagts till för {user.first_name} {user.last_name}.
				</MDBAlert> : null}
				<MDBAlert
					color='danger'
					autohide
					position='top-right'
					delay={4000}
					appendToBody
					open={licenseError}
					onClose={() => setLicenseError(false)}
				>
					Något gick fel vid ändring av licens.
				</MDBAlert>
				<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
					<MDBModalDialog size="xl">
						<MDBModalContent>
							<MDBModalHeader>
								<MDBModalTitle>Hantera licenser</MDBModalTitle>
								<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
								</MDBModalHeader>
									<MDBModalBody>
									{loading || !user ? 
										<MDBRow className="d-flex justify-content-center py-5">
											<MDBSpinner color='primary'>
												<span className='visually-hidden'>Loading...</span>
											</MDBSpinner>
										</MDBRow> :
										<>
											<MRT_Table table={table} />
											<hr></hr>
												<MDBRow className='my-2'>
													<MDBCol>
													<MDBCheckbox
														id='controlledCheckbox'
														label='Inkludera betalning'
														checked={checked}
														onChange={() => setChecked(!checked)}
													/>
													</MDBCol>
												</MDBRow>
												
												{checked ? 
												<MDBRow>
													<MDBCol md={4}>
														<MDBDatepicker 
															disableFuture 
															selectOnClick
															name='date'
															format='yyyy-mm-dd'
															value={formValue.date}
															onChange={(e) => {
																setFormValue({ ...formValue, ['date']: e });
															}}
															inputLabel='Välj datum'/>		
													</MDBCol>		
													<MDBCol md={4}>											
														<MDBInput
															value={formValue.price}
															name='price'
															onChange={onChange}
															label='Pris'
														/>
													</MDBCol>
													<MDBCol md={4}>
														<MDBSelect
															preventFirstSelection
															data={[
																{ text: '', hidden: true },
																{ text: 'Bankgiro', value: 'Bankgiro' },
																{ text: 'Bankkonto', value: 'Bankkonto' },
																{ text: 'Kort', value: 'CreditCard' },
																{ text: 'Swish', value: 'Swish' },																		
															]}
															label='Betalmetod'
															value={formValue.payment_method}
															onChange={(e) => {
																setFormValue({ ...formValue, ['payment_method']: e.value });
															}}
														/>
													</MDBCol>
												</MDBRow>

												: null}
												<MDBRow className='mt-3' between>
													<MDBCol md={6}>
													<MDBSelect 
														required
														preventFirstSelection
														data={filterSportList(user.skyttekort)}
														label='Välj idrottskommitté'
														value={formValue.sportgren_id}
														onChange={(e) => {
															setFormValue({ ...formValue, ['sportgren_id']: e.value });
														}}
														/>
													</MDBCol>
													<MDBCol>
														<MDBBtn disabled={!valid || !GetSkyttekortStatus(user.skyttekort) || !GetAllowedToBuy(user.licenser)} onClick={() => addLicense()}>
															{ageGroup === "S" ? "Lägg till licens senior": "Lägg till licens junior"}
														</MDBBtn>
													</MDBCol>
												</MDBRow>
										</>
									}
									</MDBModalBody>
								<MDBModalFooter>
								<MDBBtn color='secondary' onClick={params.close}>
									Stäng
								</MDBBtn>
							</MDBModalFooter>
						</MDBModalContent>
					</MDBModalDialog>
				</MDBModal>
			</>

}