import { useNavigate, useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
  MDBBtn,
  MDBIcon, 
  MDBTableHead, 
  MDBTableBody,
	MDBPopover,
	MDBPopoverBody,
	MDBPopoverHeader,
  MDBBadge,
	MDBSwitch,
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBAlert
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
 import {
	 MaterialReactTable,
	 useMaterialReactTable,
 } from 'material-react-table';
import { fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";
import { GetAllowedToBuy } from "../license/utils/GetLicenseDates";

export async function loader({ params }) {

	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	let user = null;
	if(params.key_id === decoded.kid){
		user = await fetch_get("app/user/all/" + params.key_id);

		let isParent = false;
		user.data.parents.map(parent => {
			const loggedInParent = localStorage.getItem("key_id")
			if(parent.key_id == loggedInParent){
				isParent = true
			}
		})
	
		const obj = {
			user: user,
			guest: decoded.kid == params.key_id ? false : true,
			parent: isParent
		}
		return obj
	}

	/* 

	if(!obj.parent && obj.guest){
		if(!window.location.href.endsWith("/profile")){
			return <Navigate to="/unauthorized" replace />;
		}
	} */

	return null;
	
	
}

export default function UserLicense(){

	const loadObject = useLoaderData();
	const user = loadObject.user.data;
  const licenses = user.licenser;
	const grenar = user.skyttekort.map(kort => {
		return kort.gren_name + " "
	});
	const grenar_ordered = grenar.sort();
	const [tableData, setTableData] = useState(licenses); 
	const navigate = useNavigate();
	const [showAll, setShowAll] = useState(false);
	const skyttekortExists = user.skyttekort.length > 0 ? true : false
	const showMissingSkyttekortAlert = user.skyttekort.length > 0 ? false : true

	useEffect(() => {
		if(!showAll){
			const todaysDate = new Date().getTime();
			const filterData = tableData.filter((row) => (row.status == "ACTIVE" || row.status == "PENDING"))
			setTableData(filterData.map(data => {
				return { ...data, sportgren_name: grenar_ordered}
			}))
		} else {
			setTableData(licenses.map(data => {
				return { ...data, sportgren_name: grenar_ordered}
			}))
		}

  }, [showAll]);

	function getStatusBadge(order){
		let icon,text,color;
		switch(order){
			case "ACTIVE":
				icon = "check"
				text = "Aktiv"
				color = "success"
				break;
			case "PENDING":
				icon = "check"
				text = "Ej påbörjad"
				color = "success"
				break;
			case "EXPIRED":
				icon = "calendar-check"
				text = "Utgången"
				color = "warning"
				break;
			case "DELETED":
				icon = "times"
				text = "Raderad"
				color = "danger"
				break;
			case "DISABLED":
				icon = "times"
				text = "Inaktiverad"
				color = "danger"
				break;
			case "PAUSED":
				icon = "pause"
				text = "Pausad"
				color = "warning"
				break;
			case "LOCKED":
				icon = "lock"
				text = "Låst"
				color = "danger"
				break;
			case "DEFAULT":
				icon = "question"
				text = "Okänd status"
				color = "warning"
		}
		return <>
						<MDBPopover
							poperStyle={{width:150}}
							dismiss
							tag='a'
							style={{ cursor: 'pointer'}}
							placement='left'
							btnChildren={<MDBBadge className="mx-2" pill color={color} light><MDBIcon fas icon={icon} /></MDBBadge>}
						>
						<MDBPopoverBody>
							<b>{text}</b>
						</MDBPopoverBody>
						</MDBPopover>
					</>
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'lic_name', //access nested data with dot notation
        header: 'Namn',
        size: 150,
      },
			{
        accessorKey: 'sportgren_name', //access nested data with dot notation
        header: 'Gren',
        size: 150,
      },
			{
        accessorKey: 'gren_name', //access nested data with dot notation
        header: 'Idrottskommitté',
        size: 50,
      },
      {
        accessorKey: 'start_date', //access nested data with dot notation
        header: 'Startdatum',
        size: 150,
      },
			{
        accessorKey: 'end_date', //access nested data with dot notation
        header: 'Giltig tom.',
        size: 50,
      },
			{
				accessorFn: (originalRow) => (originalRow.status),
				id: 'status',
				header: 'Status',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell }) => (
					getStatusBadge(cell.getValue())
				)
			},
			{
        accessorKey: 'add_date', //access nested data with dot notation
        header: 'Betald',
        size: 50,
				Cell: ({ cell }) => (
					new Date(cell.getValue()).toLocaleDateString('en-CA'))
      },
    ],
    [licenses],
  );
	
	const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData,
		initialState: { 
			columnPinning: {
        right: ['mrt-row-actions'],
      },
		},
    enableColumnFilters: false,
		enableColumnActions: false,
		enableDensityToggle: false,
		enableFullScreenToggle: false,
		enableHiding: false,
		enableFilters: false,
		enableRowActions: true,
		enableStickyFooter: true,
		muiTableContainerProps: { sx: { maxHeight: window.innerHeight*0.65 } },
		localization:MRT_Localization_SV,
		renderTopToolbarCustomActions: () => (
			<MDBSwitch id='flexSwitchCheckDefault' onChange={() => setShowAll(!showAll)} label='Visa alla tävlingslicenser' />
		),
		renderRowActions: ({ row }) => (
				<MDBBtn data-bs-toggle="tooltip" href={window.location.protocol + "//" + window.location.hostname + "/api/app/user/download-membercard/" + user.key_id} data-bs-placement="top" title="Ladda ner medlemskort" tag='a' color='none' style={{ color: '#0065A4' }} className='mx-2 my-0'>
					<MDBIcon className='py-0' far icon='file-pdf'/>
				</MDBBtn>
		)
  });
  
  if(licenses.length > 0){
    return (
			<>
				<MDBContainer className="py-4">
					{showMissingSkyttekortAlert ? <MDBAlert
						className='d-flex justify-content-between'
						defaultOpen
						autohide 
						delay={10000}
						color='warning'
						dismissBtn
						position='bottom-center'>
						<p className='mb-0'>
						Skyttekort saknas för {user.first_name + " " + user.last_name}.
						</p>
					</MDBAlert> : null}
					<h3>Tävlingslicenser</h3>
					<hr/>
					<MDBRow className='d-flex justify-content-center'>
						<MDBCol md="10" className="mb-3">
							<BorderCol headline="Mina tävlingslicenser" className='col-md-12 col-sm-12 square border border-primary rounded-6 p-4 mx-1 position-relative'>
								<MaterialReactTable table={table} />
							</BorderCol>
						</MDBCol>
					</MDBRow>
					<MDBRow className='d-flex justify-content-center'>
						<MDBCol className="mt-3 text-center">
							<MDBBtn color='primary' size='sm' disabled={!skyttekortExists || !GetAllowedToBuy(licenses)} onClick={() => {navigate("/license-options",{state: [user]})}}>Köp tävlingslicens</MDBBtn>						
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</>
		);
  } else {
		return (<>
			<MDBContainer className="py-4">
				{showMissingSkyttekortAlert ? <MDBAlert
					className='d-flex justify-content-between'
					defaultOpen
					autohide 
					delay={10000}
					color='warning'
					dismissBtn
					position='bottom-center'>
					<p className='mb-0'>
					Skyttekort saknas för {user.first_name + " " + user.last_name}.
					</p>
				</MDBAlert> : null}
				<h3>Tävlingslicenser</h3>
				<hr/>
				<p align='middle'>Inga licenser finns att visa.</p>
				<MDBRow>
					<MDBCol size="5"/>
					<MDBCol size="2" className="mt-3 text-center">
						<MDBBtn color='primary' size='sm' disabled={!skyttekortExists} onClick={() => {navigate("/license-options",{state: user})}}>Köp tävlingslicens</MDBBtn>						
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
		)
	}

 
}