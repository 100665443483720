
import React,{ useMemo, useState } from 'react';
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBSwitch
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box
} from '@mui/material';
import { fetch_get } from '../../../../services/common';

export default function KansliMembers() {

	const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
	const [showAll, setShowAll] = useState(false);

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'first_name',
        header: 'Förnamn',
        size: 50
      },
			{
        accessorKey: 'last_name',
        header: 'Efternamn',
        size: 50
      },
			{
        accessorKey: 'birth_date',
        header: 'Födelsedatum',
        size: 50,
				filterVariant: 'date-range'
      },
			{
        accessorKey: 'licens_nr',
        header: 'IID-nr',
        size: 50,
      },
			{
        accessorKey: 'email',
        header: 'E-post',
        size: 50,
      },
			{
        accessorKey: 'forening',
        header: 'Förening',
        size: 50,
				filterVariant: 'autocomplete',
      },
			{
        accessorKey: 'distrikt',
        header: 'Distrikt',
        size: 50,
				filterVariant: 'autocomplete',
      },
			{
				accessorFn: (originalRow) => (originalRow.foreign_user === 'true' ? 'true' : 'false'),
				id: 'foreign_user',
				header: 'Utländsk',
				size: 50,
				filterVariant: 'checkbox',
				Cell: ({ cell }) => (
					<>
						{cell.getValue() === 'true' ? 
							<>Ja</> : <>Nej</>}
					</>
				)
			},
    ],
    [],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
			showAll
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/users/',
        window.location.protocol + "//" + window.location.hostname,
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function padDigits(num) {
				return num.toString().padStart(2, '0');
			}
			
			function newDate(date) {
				return [
					date.getFullYear(),
					padDigits(date.getMonth() + 1),
					padDigits(date.getDate()),
				].join('-');
			}

			function filterFunction(){
				let filterString = ""

				if(!showAll){
					if(columnFilters.length > 0 || globalFilter){
						filterString = filterString.concat("(is_dead=false) AND ")
					} else {
						filterString = filterString.concat("(is_dead=false)")
					}
				}
				
				if(globalFilter && globalFilter.length > 0 ){
					filterString = filterString.concat("(first_name LIKE '%" + 
						globalFilter + "%' OR last_name LIKE '%" + 
						globalFilter + "%' OR fullname LIKE '%" + 
						globalFilter + "%' OR birth_date LIKE '%" + 
						globalFilter + "%' OR person_nr LIKE '%" + 
						globalFilter + "%' OR licens_nr LIKE '%" +
						globalFilter + "%' OR email LIKE '%" +
						globalFilter + "%' OR forening LIKE '%" +
						globalFilter + "%' OR distrikt LIKE '%" +
						globalFilter + "%')")
					if(columnFilters.length > 0){
						filterString = filterString.concat(" AND ")
					}
				}
				if(columnFilters.length > 0){
					filterString = filterString.concat("(")
					columnFilters.map((col, index) => {
						let searchParam = " LIKE '%" + col.value + "%'"
						if(col.id === "birth_date"){
							let dateFilter = ""
							if(col.value[0]){
								dateFilter = dateFilter.concat("birth_date > '" + newDate(col.value[0].$d) + "'")
							}
							if(col.value[0] && col.value[1]){
								dateFilter = dateFilter.concat(" AND ")
							}
							if(col.value[1]){
								dateFilter = dateFilter.concat("birth_date < '" + newDate(col.value[1].$d) + "'")
							}
							if(!col.value[0] && !col.value[1]){
								dateFilter = dateFilter.concat("1=1")
							}
							filterString = filterString.concat(dateFilter)
						} else if (col.id === "roles"){
							let roleFilter = ""
							col.value.map((desc,index) => {
								if(index == 0){
									roleFilter = "(roles LIKE " + "'%" + desc + "%'"
								} else {
									roleFilter = roleFilter.concat(" OR roles LIKE '%" + desc + "%'")
								}
							})
							roleFilter = roleFilter.concat(")")
							filterString = filterString.concat(roleFilter)
						} else {
							if(index == 0){
								filterString = filterString.concat(col.id + searchParam)
							} else {
								filterString = filterString.concat(" AND " + col.id + searchParam)
							}
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
			if(globalFilter || columnFilters.length > 0 || !showAll){
				fetchURL.searchParams.set('_filter', filterFunction());
			}
			
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}
      const resp = await fetch_get(fetchURL.href.split(window.location.hostname).pop())
      return resp;
    },
    placeholderData: keepPreviousData,
  });

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true
		},
		enableFacetedValues: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableColumnActions: false,
		enableFullScreenToggle: false,
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting
    },
		muiTableBodyRowProps: ({ row }) => ({
			sx: {
				backgroundColor: row.original.is_dead === "true" ? '#f25252' : '#fff',
			},
		}),
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
					<Box className="me-4">
						<MDBSwitch id='flexSwitchCheckDefault' onChange={() => setShowAll(!showAll)} label='Inkludera avlidna' />
					</Box>
        </Box>

      );
		}
  });

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Medlemmar</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
				</MDBRow>
				
			</MDBContainer>
		</>
          );
}