import { fetch_post } from "../../../../services/common";

export async function EditStatusLicense(user_key,licenseID,status){
	
	const response = await fetch_post("app/user/do_submit/" + user_key, {
		action: "remove_licens",
    id: licenseID,
    db_status: status

	});
	
	if (response.message !== "OK") {
		//throw new Error(
		 // `This is an HTTP error: The status is ${response.status}`
		//);
	} 
	return  response;
}