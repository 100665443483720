import React,{ useMemo, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBPopover,
	MDBPopoverHeader,
	MDBPopoverBody,
	MDBBtn,
	MDBSelect
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
	MRT_ToolbarAlertBanner,
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box,
} from '@mui/material';
import DistrictAssociationsModal from '../../district/associations/DistrictAssociationsModal';
import { extExcelExport } from '../../../documents/ExcelExport';
import { fetch_get } from '../../../../services/common';

export default function KansliAssociations() {

	const [columnFilters, setColumnFilters] = useState([]);
	const [selectedRows, setSelectedRows] = useState([])
	const [rowSelection, setRowSelection] = useState({})
  const [globalFilter, setGlobalFilter] = useState('');
	const [association, setAssociation] = useState(null)
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
	const [associationModal, setAssociationModal] = useState(false);
	const associationData = []
	const [loading, setLoading] = useState(false)
	const [allRows, setAllRows] = useState(null)
	const [selectedColumns, setSelectedColumns] = useState(null)
	const [exportSelectValue, setExportSelectValue] = useState([
		"Namn",
		"Organisationsnummer",
		"Epost",
		"FakturaEpost",
		"Hemsida",
		"Distrikt",
		"Anläggningar",
		"Adresser",
		"Bankgiro",
		"Plusgiro",
		"Swish",
		"OrdförandeNamn",
		"OrdförandeEpost",
		"OrdförandeTelefon",
		"SekreterareNamn",
		"SekreterareEpost",
		"SekreterareTelefon",
		"KassörNamn",
		"KassörEpost",
		"KassörTelefon"
	]);
	const [exportSelectData, setExportSelectData] = useState([
		{ text: 'Namn', value: "Namn" },
		{ text: 'Organisationsnummer', value: "Organisationsnummer" },
		{ text: 'Epost', value: "Epost" },
		{ text: 'Faktura E-post', value: "FakturaEpost" },
		{ text: 'Hemsida', value: "Hemsida" },
		{ text: 'Distrikt', value: "Distrikt" },
		{ text: 'Anläggningar', value: "Anläggningar" },
		{ text: 'Adresser', value: "Adresser" },
		{ text: 'Bankgiro', value: "Bankgiro" },
		{ text: 'Plusgiro', value: "Plusgiro" },
		{ text: 'Swish', value: "Swish" },
		{ text: 'Ordförande namn', value: "OrdförandeNamn" },
		{ text: 'Ordförande e-post', value: "OrdförandeEpost" },
		{ text: 'Ordförande telefon', value: "OrdförandeTelefon" },
		{ text: 'Sekreterare namn', value: "SekreterareNamn" },
		{ text: 'Sekreterare e-post', value: "SekreterareEpost" },
		{ text: 'Sekreterare telefon', value: "SekreterareTelefon" },
		{ text: 'Kassör namn', value: "KassörNamn" },
		{ text: 'Kassör e-post', value: "KassörEpost" },
		{ text: 'Kassör telefon', value: "KassörTelefon" }
	]);

	const toggleAssociationModal = (association) => {
		setAssociation(association)
		setAssociationModal(!associationModal)
	};

	function getContactInfo(contactObj,typeId){
		let contact = "-"
		contactObj.map(obj => {
			if(obj.ContactTypeId == typeId){
				contact = obj.ContactInfo
			}
		})
		return contact
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'name',
        header: 'Förening',
        size: 100,
				Cell: ({ cell,row }) => (
					<MDBBtn onClick={() => toggleAssociationModal(row.original)} tag='a' color='none' style={{ color: '#00609C' }} className='mx-0 my-0'>
						{cell.getValue()}
					</MDBBtn>
				)
      },
			{
        accessorKey: 'org_nr',
        header: 'Organisationsnummer',
        size: 20,
      },
			{
        accessorKey: 'distrikt_name',
        header: 'Distrikt',
        size: 20,
      },
			{
        accessorKey: 'email',
        header: 'E-post',
        size: 200,
				Cell: ({ row }) => (
					<>
						{row.original.json ?
							<a href={"mailto:" + getContactInfo(JSON.parse(row.original.json).Contacts,1)}>
								{getContactInfo(JSON.parse(row.original.json).Contacts,1)}
							</a> : null}
					</>
				)
      },
			{
        accessorKey: 'website',
        header: 'Hemsida',
        size: 200,
				Cell: ({ row }) => (
					<>
						{row.original.json ?
							<a href={getContactInfo(JSON.parse(row.original.json).Contacts,4).startsWith("http") ? getContactInfo(JSON.parse(row.original.json).Contacts,4) : "//" + getContactInfo(JSON.parse(row.original.json).Contacts,4)} target='_blank'>
								{getContactInfo(JSON.parse(row.original.json).Contacts,4)}
							</a> : null}
					</>
				)
      },
    ],
    [],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
			selectedRows
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/foreningar/',
        window.location.protocol + "//" + window.location.hostname,
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function filterFunction(){
				
				
				let filterString = ""
				if(Object.keys(rowSelection).length > 0){
					Object.entries(rowSelection).forEach(([key], index) => {
						if(index === 0){
							filterString = filterString.concat(" id = " + `${key}`)
						} else {
							filterString = filterString.concat(" OR id = " + `${key}`)
						}
					});
					
				}
				if(globalFilter && globalFilter.length > 0 ){
					if(Object.keys(rowSelection).length > 0){
						filterString = filterString.concat(" OR ")
					} 
					filterString = filterString.concat("(name LIKE '%" + 
						globalFilter + "%' OR org_nr LIKE '%" + 
						globalFilter + "%')")
					if(columnFilters.length > 0){
						filterString = filterString.concat(" AND ")
					}
				}
				if(columnFilters.length > 0){
					if(Object.keys(rowSelection).length > 0){
						filterString = filterString.concat(" OR ")
					}
					filterString = filterString.concat("(")
					columnFilters.map((col, index) => {
						let searchParam = " LIKE '%" + col.value + "%'"
						if(index == 0){
							filterString = filterString.concat(col.id + searchParam)
						} else {
							filterString = filterString.concat(" AND " + col.id + searchParam)
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
			if(globalFilter || columnFilters.length > 0 || Object.keys(rowSelection).length > 0){
				fetchURL.searchParams.set('_filter', filterFunction());
			}
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}

      const resp = await fetch_get(fetchURL.href.split(window.location.hostname).pop())
			setAllRows(resp.data)
      return resp;
    },
    placeholderData: keepPreviousData,
  });

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true
		},
		selectAllMode: 'all',
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
		enableStickyFooter: true,
		muiTableContainerProps: { sx: { maxHeight: window.innerHeight*0.65 } },
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableRowPinning: true,
    enableStickyHeader: true,
    rowPinningDisplayMode: 'select-top',
		enableRowSelection: true,
		onRowSelectionChange: setRowSelection,
		enableCellActions: true,
    muiSelectCheckboxProps: { color: 'secondary' },
		enableColumnActions: false,
		enableFullScreenToggle: false,
		getRowId: (originalRow) => originalRow.id,
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
			rowSelection
    },
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />						
						<MRT_ToolbarAlertBanner table={table} />
						{selectedRows.length > 0 ?
							<MDBPopover
								poperStyle={{width:250}}
								dismiss
								color='tertiary'
								btnChildren='Visa valda'
							>
							<MDBPopoverHeader>Valda föreningar</MDBPopoverHeader>
							<MDBPopoverBody>
								{selectedRows.map(row => {
									return <div key={row.id}>{row.original.name}</div>
								})}
							</MDBPopoverBody>
						</MDBPopover> : null}
						
          </Box>
					<Box className="me-2">
						<MDBCol><MDBSelect
							data={exportSelectData}
							multiple
							label='Välj kolumner'
							optionsSelectedLabel='kolumner valda'
							value={exportSelectValue}
							selectAllLabel='Välj alla'
							onValueChange={(e) => {
								setSelectedColumns(e)
								setExportSelectValue(e.value);
							}}
						/></MDBCol>
						<MDBCol><MDBBtn className='mt-2' onClick={() => exportAssociationList()}>Exportera {!selectedRows.length > 0 ? "alla" : "valda"} föreningar</MDBBtn></MDBCol>
						
					</Box>
        </Box>

      );
		}
  });

	useEffect(() => {
		associationData.splice(0, associationData.length);
		if(selectedRows.length > 0){
			selectedRows.map(association => {
				if(association.original.json){
					const ioAssociation = JSON.parse(association.original.json)
					associationData.push(
						{ 
							Namn: ioAssociation.DescribingName, 
							Organisationsnummer: ioAssociation.OrganisationNumber,
							Epost: getContactInfo(ioAssociation.Contacts,1),
							FakturaEpost: getContactInfo(ioAssociation.Contacts,16),
							Hemsida: getContactInfo(ioAssociation.Contacts,4),
							Distrikt: getParentOrgInfo(ioAssociation.ParentBelongings,4),
							Anläggningar: "",
							Adresser: ioAssociation.Addresses.map(address => {
								if(address.AddressTypeId === 3){
									return address.Line1 + " " + address.Line2 + " " + address.PostalNumber + " " + address.PostalCity
								}
							}).toString(),
							Bankgiro: getAccountInfo(ioAssociation.Accounts,1),
							Plusgiro: getAccountInfo(ioAssociation.Accounts,5),
							Swish: getAccountInfo(ioAssociation.Accounts,3),
							OrdförandeNamn: getPersonInfo(ioAssociation.Persons,10,99),
							OrdförandeEpost: getPersonInfo(ioAssociation.Persons,10,1),
							OrdförandeTelefon: getPersonInfo(ioAssociation.Persons,10,5),
							SekreterareNamn: getPersonInfo(ioAssociation.Persons,12,99),
							SekreterareEpost: getPersonInfo(ioAssociation.Persons,12,1),
							SekreterareTelefon: getPersonInfo(ioAssociation.Persons,12,5),
							KassörNamn: getPersonInfo(ioAssociation.Persons,14,99),
							KassörEpost: getPersonInfo(ioAssociation.Persons,14,1),
							KassörTelefon: getPersonInfo(ioAssociation.Persons,14,5),
							}
					)
				} else {
					associationData.push(
						{ 
							Namn: association.original.name, 
							Organisationsnummer: "-",
							Epost: "-",
							FakturaEpost: "-",
							Hemsida: "-",
							Distrikt: "-",
							Anläggningar: "-",
							Adresser: "-",
							Bankgiro: "-",
							Plusgiro: "-",
							Swish: "-",
							OrdförandeNamn: "-",
							OrdförandeEpost: "-",
							OrdförandeTelefon: "-",
							SekreterareNamn: "-",
							SekreterareEpost: "-",
							SekreterareTelefon: "-",
							KassörNamn: "-",
							KassörEpost: "-",
							KassörTelefon: "-",
							}
						)
					}
				})
		} else {
			if(allRows){
				allRows.map(association => {
					if(association.json){
						const ioAssociation = JSON.parse(association.json)
						associationData.push(
							{ 
								Namn: ioAssociation.DescribingName, 
								Organisationsnummer: ioAssociation.OrganisationNumber,
								Epost: getContactInfo(ioAssociation.Contacts,1),
								FakturaEpost: getContactInfo(ioAssociation.Contacts,16),
								Hemsida: getContactInfo(ioAssociation.Contacts,4),
								Distrikt: getParentOrgInfo(ioAssociation.ParentBelongings,4),
								Anläggningar: "",
								Adresser: ioAssociation.Addresses.map(address => {
									if(address.AddressTypeId === 3){
										return address.Line1 + " " + address.Line2 + " " + address.PostalNumber + " " + address.PostalCity
									}
								}).toString(),
								Bankgiro: getAccountInfo(ioAssociation.Accounts,1),
								Plusgiro: getAccountInfo(ioAssociation.Accounts,5),
								Swish: getAccountInfo(ioAssociation.Accounts,3),
								OrdförandeNamn: getPersonInfo(ioAssociation.Persons,10,99),
								OrdförandeEpost: getPersonInfo(ioAssociation.Persons,10,1),
								OrdförandeTelefon: getPersonInfo(ioAssociation.Persons,10,5),
								SekreterareNamn: getPersonInfo(ioAssociation.Persons,12,99),
								SekreterareEpost: getPersonInfo(ioAssociation.Persons,12,1),
								SekreterareTelefon: getPersonInfo(ioAssociation.Persons,12,5),
								KassörNamn: getPersonInfo(ioAssociation.Persons,14,99),
								KassörEpost: getPersonInfo(ioAssociation.Persons,14,1),
								KassörTelefon: getPersonInfo(ioAssociation.Persons,14,5),
								}
						)
					} else {
						associationData.push(
							{ 
								Namn: association.name, 
								Organisationsnummer: "-",
								Epost: "-",
								FakturaEpost: "-",
								Hemsida: "-",
								Distrikt: "-",
								Anläggningar: "-",
								Adresser: "-",
								Bankgiro: "-",
								Plusgiro: "-",
								Swish: "-",
								OrdförandeNamn: "-",
								OrdförandeEpost: "-",
								OrdförandeTelefon: "-",
								SekreterareNamn: "-",
								SekreterareEpost: "-",
								SekreterareTelefon: "-",
								KassörNamn: "-",
								KassörEpost: "-",
								KassörTelefon: "-",
								}
						)
					}
				})
			}
		}
	}, [selectedRows,selectedColumns, allRows]);

	function getContactInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.ContactTypeId == typeId){
				contact = obj.ContactInfo
			}
		})
		return contact
	}

	function getParentOrgInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.ParentOrganisationTypeId == typeId){
				contact = obj.FullName
			}
		})
		return contact
	}

	function getAccountInfo(obj,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.AccountTypeId == typeId){
				contact = obj.AccountNumber
			}
		})
		return contact
	}

	function getPersonInfo(obj,roleId,typeId){
		let contact = "-"
		obj.map(obj => {
			if(obj.Roles[0].RoleType.RoleTypeId === roleId){
				if(typeId === 99){
					contact = obj.FirstName + " " + obj.LastName
				} else {
					obj.Contacts.map(cont => {
						if(cont.ContactTypeId === typeId){
							contact = cont.ContactInfo
						}
					})
				}
			}
		})
		return contact
	}

	useEffect(() => {
		setSelectedRows(table.getSelectedRowModel().rows)
	}, [rowSelection]);

	const exportAssociationList = () => {
		const objToDelete = []
		exportSelectData.map(obj => {
			if(!selectedColumns.find((val) => val.value == obj.value)){
				objToDelete.push(obj.value)				
			}
		})
		associationData.map((association,i) => {
			objToDelete.map(obj => {
				delete associationData[i][obj]
			})
		})
		extExcelExport(associationData,null,"Förening Export")
	}
	

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Föreningar</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
				</MDBRow>
				{(associationModal && association) ? <DistrictAssociationsModal association={association} show={associationModal} close={() => setAssociationModal(false)}/> : null}
			</MDBContainer>
		</>
          );
}