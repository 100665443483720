import React, { useState } from 'react';
import {  Link, } from 'react-router-dom';
import {
	MDBContainer,
	MDBAutocomplete,
	MDBRow,
	MDBCol
} from 'mdb-react-ui-kit';
import { fetch_json } from '../../../../services/common';

export default function KansliUserLogin() {

	const [isLoading, setIsLoading] = useState(false);
	const [asyncData, setAsyncData] = useState([]);
	const [tokenData, setTokenData] = useState(null);
	const [value1, setValue] = useState("");


	const onAsyncSearch = async (query) => {
		if (query.length >= 3) {
			setIsLoading(true);
			//api/app/list/users-search
			const data = await fetch_json("app/list/users-search?_search=" + query);
			setIsLoading(false);
			console.log('data', data);
			setAsyncData(data.data);
		} else {
			setAsyncData([]);
		}
		setValue(query);
	};

	const onAsyncSelect = async (item) => {
		console.log('otem', item);
		//https://dev.svsf-ta.se/api/app/user/user-sysadmin/2587f7d3-e2c5-11ed-b9c6-00505692ffcc
		const data = await fetch_json("app/user/user-sysadmin/" + item.key_id);
		console.log('dddd', data.data.user);
		setTokenData(data.data);
		// setValue("");

	};


	return (
		<>
			<MDBContainer className="py-4">
				<h1>Systemadministratör - Inloggning</h1>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
				<MDBAutocomplete
					data={asyncData}
					isLoading={isLoading}
					label='Sök medlem'
					// displayValue={(row) => row.full_name + "[" + row.key_id +  "]"}
					displayValue={(row) => row.key_id}
					listHeight='300px'
					onSearch={onAsyncSearch}
					onSelect={onAsyncSelect}
					noResults='-'
					// value={value1}
					itemContent={(result) => (
						<div className='autocomplete-custom-item-content'>
							<div className='autocomplete-custom-item-title'>{result.full_name} [{result.licens_nr}/{result.birth_date}] <small>({result.email})</small></div>
						</div>
					)}
				/>
				{tokenData && (
					<>
						
						<h3 className='p-4'>Logga in som : {tokenData.user.name}</h3>
						<Link className=""
							as={Link}
							to={"/sysadmin-login/" + tokenData.user.key_id + "/" + tokenData.user.token + "/" + tokenData.sysadmin.key_id + "/" + tokenData.sysadmin.token}
							onClick={(event) => { 
								event.preventDefault();
								console.log('ownMark', event);
								alert('Du måste högerklicka och välja: Öppna länk i privat fönster') 
								
							}}
						>
							Högerklicka på denna länk, välj : Öppna länk i privat fönster
						</Link>

					</>
				)
				}</MDBCol>
				</MDBRow>
			</MDBContainer>
		</>
	);
}