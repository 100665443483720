import React, { useEffect,useState, useMemo } from 'react';
import {    
  MDBBtn,
	MDBRow,
	MDBSpinner,
	MDBIcon,
	MDBModal,
	MDBModalBody,
	MDBModalContent,
	MDBModalTitle,
	MDBModalFooter,
	MDBModalHeader,
	MDBModalDialog,
	MDBAlert,
	MDBBadge,
	MDBPopconfirm,
	MDBPopconfirmMessage,
	MDBCol,
	MDBSelect
 } from 'mdb-react-ui-kit';
 import {
	MRT_Table,
	useMaterialReactTable,
} from 'material-react-table';
 import { fetch_get } from '../../../services/common';
 import { EditSkyttekort } from './utils/EditSkyttekort';
import { Box } from '@mui/material';
import { AddSkyttekort } from './utils/AddSkyttekort';

export default function EditSkyttekortModal(params){
	
	const [loading, setLoading] = useState(false);
	const user = params.sentUser;
	const [sportVal, setSportVal] = useState(null)
	const [skyttekortDeleted, setSkyttekortDeleted] = useState(false)
	const [skyttekortAdded, setSkyttekortAdded] = useState(false)
	const [skyttekortError, setSkyttekortError] = useState(false)
	const [asyncData, setAsyncData] = useState([]);
	const [sportList, setSportList] = useState(null)
	const [changeMade, setChangeMade] = useState(false)

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'def_skyttekort_name',
        header: 'Skyttekort',
        size: 50
      },
			{
        accessorKey: 'skyttekort_datum',
        header: 'Datum',
        size: 50
      },
			{
        accessorKey: 'status',
        header: 'Status',
        size: 50,
				Cell: ({ cell,row }) => (
					<>
						{cell.getValue() === 'ACTIVE' ? 
							<MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Aktiv" className="mx-2" pill color='success' light><MDBIcon fas icon="check" /></MDBBadge> 
							:<MDBBadge data-bs-toggle="tooltip" data-bs-placement="top" title="Inaktiverad" className="mx-2" pill color='danger' light><MDBIcon fas icon="times" /></MDBBadge>}
					</>
				)
      },
    ],
    [],
  );

	const addSkyttekort = () => {

			AddSkyttekort(user.user_key_id,sportVal.value,user.iid_nr).then(obj => {
				if(obj.status == "OK"){
					setSkyttekortAdded((prev) => !prev)
					setChangeMade(!changeMade)
				} else {
					setSkyttekortError((prev) => !prev)
				}
			});
		
	};

	const editSkyttekort = (status,skyttekortID) => {

		EditSkyttekort(status, user.user_key_id,skyttekortID).then(obj => {
			if(obj.status == "OK"){
				if(status === "ACTIVE"){
					setSkyttekortAdded((prev) => !prev)
				} else {
					setSkyttekortDeleted((prev) => !prev);
				}
				setChangeMade(!changeMade)
			} else {
				setSkyttekortError((prev) => !prev)
			}
		});
		
	}

	useEffect(() => {
		setLoading(true)
		fetch_get("app/user/skyttekort/" + user.user_key_id)
			.then((data) =>
				setTimeout(() => {
					setAsyncData(data.data)
					setLoading(false)
				}, 3000)
			);
		fetch_get("app/list/lov-skyttekort/")
			.then((data) =>
				setTimeout(() => {
					setSportList(data.data)
					setLoading(false)
				}, 3000)
			);
	}, [changeMade]);

	const table = useMaterialReactTable({
    columns: tableColumns,
    data: asyncData,
		enableColumnActions: false,
		localization: {
			noRecordsToDisplay: "Skyttekort saknas",
			actions: "Åtgärder"
		},
		initialState: { 
			columnPinning: {
        right: ['mrt-row-actions'],
      },
		},
		enableRowActions: true,
		renderRowActions: ({ row }) => (
			row.original.status === "ACTIVE" ?
			<Box>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Inaktivera skyttekort" modal color='none' className='m-1' style={{ color: 'red', background: 'white', border: 'none' }} btnChildren={
					
						<MDBIcon far icon="trash-alt" size='lg' />
					} onConfirm={() => editSkyttekort("DELETED", row.original.id)}>
					<MDBPopconfirmMessage>Ta bort skyttekort?</MDBPopconfirmMessage>
				</MDBPopconfirm>
			</Box> :
			<Box>
				<MDBPopconfirm data-bs-toggle="tooltip" data-bs-placement="top" title="Aktivera skyttekort" modal color='none' className='m-1' style={{ color: '#0065A4', background: 'white', border: 'none' }} btnChildren={
					
						<MDBIcon fas icon="plus" size='lg' />
					} onConfirm={() => editSkyttekort("ACTIVE", row.original.id)}>
					<MDBPopconfirmMessage>Återatkviera skyttekort?</MDBPopconfirmMessage>
				</MDBPopconfirm>
			</Box>
		)
  });

    return <>
						<MDBAlert
							color='primary'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={skyttekortDeleted}
							onClose={() => setSkyttekortDeleted(false)}
						>
							Skyttekort har avslutats för {user.first_name} {user.last_name}.
						</MDBAlert>
						<MDBAlert
							color='primary'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={skyttekortAdded}
							onClose={() => setSkyttekortAdded(false)}
						>
							Skyttekort har lagts till.
						</MDBAlert>
						<MDBAlert
							color='danger'
							autohide
							position='top-right'
							delay={4000}
							appendToBody
							open={skyttekortError}
							onClose={() => setSkyttekortError(false)}
						>
							Något gick fel vid ändring av skyttekort.
						</MDBAlert>
						<MDBModal onClose={params.close} open={params.show} tabIndex='-1'>
							<MDBModalDialog size="lg">
								<MDBModalContent>
									<MDBModalHeader>
										<MDBModalTitle>Hantera skyttekort</MDBModalTitle>
										<MDBBtn className='btn-close' color='none' onClick={params.close}></MDBBtn>
										</MDBModalHeader>
											<MDBModalBody>
											{loading ? 
												<MDBRow className="d-flex justify-content-center py-5">
													<MDBSpinner color='primary'>
														<span className='visually-hidden'>Loading...</span>
													</MDBSpinner>
												</MDBRow> :
												<>
													<MRT_Table table={table} />
													<br></br>
													<h6>Lägg till skyttekort</h6>
													<hr></hr>
													
													<br></br>
													<MDBRow between>
														<MDBCol md={4}>
															<MDBSelect
																preventFirstSelection
																label='Skyttekort'
																data={sportList ? sportList : [{ text: 'Laddar...'}]}
																name="skyttekort"			
																onChange={(val) => (setSportVal(val),console.log(val))}
															/>
														</MDBCol>
														<MDBCol md={3} className='d-flex justify-content-end'>
															<MDBPopconfirm disabled={sportVal ? false : true} modal btnChildren='Lägg till skyttekort' onConfirm={() => addSkyttekort()}>
																<MDBPopconfirmMessage>Tilldela skyttekort för <b>{sportVal ? sportVal.text : "???"}</b> till {user.user_fullname}?</MDBPopconfirmMessage>
															</MDBPopconfirm>
														</MDBCol>
													</MDBRow>
												</>
											}
											</MDBModalBody>
										<MDBModalFooter>
										<MDBBtn color='secondary' onClick={params.close}>
											Stäng
										</MDBBtn>
									</MDBModalFooter>
								</MDBModalContent>
							</MDBModalDialog>
						</MDBModal>
          </>
  
}