import React,{ useMemo, useState, useEffect } from 'react';
import { useLoaderData } from "react-router-dom";
import {    
	MDBCol,
	MDBRow,
	MDBContainer,
	MDBTypography
} from 'mdb-react-ui-kit';
import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
	useMaterialReactTable,
	MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
	MRT_ToolbarAlertBanner
} from 'material-react-table';
import {
	keepPreviousData,
	useQuery,
} from '@tanstack/react-query';
import {
	Box,Typography 
} from '@mui/material';
import { fetch_get } from "../../../../services/common";

export default function KansliPaymentHistory() {

	const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
	const [loading, setLoading] = useState(false);
	const [orderItems, setOrderItems] = useState(null);
	const [ordersList, setOrdersList] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });

	function orderItemsRender(order_id){
		if(orderItems){
			const items = orderItems.filter(item => item.order_id === order_id)
			if(items.length > 0){
				
				return items.map((item,i) => {
					return <MDBRow className="pt-1">
									{i !== 0 ? <hr></hr> : null}
									<MDBRow>
										<MDBCol md={4}><MDBTypography tag="b">Typ: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{item.name}</span></MDBCol>
										<MDBCol md={4}><MDBTypography tag="b">Licens avser: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{item.reciever_name} ({item.iid_nr})</span></MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md={4}><MDBTypography tag="b">Namn: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{item.description}</span></MDBCol>
										<MDBCol md={4}><MDBTypography tag="b">Referens: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{item.reference}</span></MDBCol>
									</MDBRow>
									<MDBRow>
										<MDBCol md={4}><MDBTypography tag="b">Pris: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{item.amount.substring(0,item.amount.length-2)+"."+item.amount.substring(item.amount.length-2)+"kr"}</span></MDBCol>
										<MDBCol md={4}><MDBTypography tag="b">Idrottskommitté: </MDBTypography></MDBCol><MDBCol><span className="text-muted">{item.sportgren_name}</span></MDBCol>
									</MDBRow>
									
								</MDBRow>
				})
			}
		}
	}

	const tableColumns = useMemo(
    () => [
			{
        accessorKey: 'order_ref',
        header: 'Referensnummer',
        size: 150,
      },
			{
        accessorKey: 'payer_name',
        header: 'Betalare',
        size: 150,
      },
			{
        accessorKey: 'add_date',
        header: 'Betaldatum',
        size: 150,
				filterVariant: 'date-range'
      },
			{
        accessorKey: 'added_manually',
        header: 'Tillagd manuellt',
        size: 50,
				Cell: ({ cell }) => (
					<>
						{cell.getValue() == 1 ? "Ja" : "Nej"}
					</>
				)
      },
			{
        accessorKey: 'description',
        header: 'Beskrivning',
        size: 50,
      },
			{
        accessorKey: 'payment_method',
        header: 'Betalningsmetod',
        size: 50,
				filterVariant: 'autocomplete',
				filterSelectOptions: ['ClickToPay', 'CreditCard', 'Trustly', 'Swish','ApplePay']
      },
			{
        accessorKey: 'amount',
        header: 'Belopp',
        size: 50,
				Cell: ({ cell }) => (
					<>
						{cell.getValue().substring(0,cell.getValue().length-2)+"."+cell.getValue().substring(cell.getValue().length-2)+"kr"}
					</>
				)
      },
			{
        accessorKey: 'status',
        header: 'Status',
        size: 50,
				filterVariant: 'autocomplete',
				filterSelectOptions: ['Aborted', 'Paid', 'Initialized', 'Complete', 'Capturing', 'Reversed']
      },
    ],
    [],
  );

  const {
    data: { data = [], meta } = {},
    isError,
    isRefetching,
    isLoading
  } = useQuery({
    queryKey: [
      'table-data',
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting
    ],
    queryFn: async () => {
      const fetchURL = new URL(
        '/api/data/pay_orders/',
        window.location.protocol + "//" + window.location.hostname,
      );

      fetchURL.searchParams.set(
        '_skip',
        `${pagination.pageIndex * pagination.pageSize}`,
      );

			function padDigits(num) {
				return num.toString().padStart(2, '0');
			}
			
			function newDate(date) {
				return [
					date.getFullYear(),
					padDigits(date.getMonth() + 1),
					padDigits(date.getDate()),
				].join('-');
			}

			function filterFunction(){
				
				let filterString = ""
				if(globalFilter && globalFilter.length > 0 ){
					filterString = filterString.concat("(order_ref LIKE '%" + 
						globalFilter + "%' OR description LIKE '%" + 
						globalFilter + "%' OR payment_method LIKE '%" + 
						globalFilter + "%' OR reciever_iid_nr LIKE '%" + 
						globalFilter + "%' OR reciever_name LIKE '%" + 
						globalFilter + "%' OR status LIKE '%" + 
						globalFilter + "%' OR amount LIKE '%" + 
						globalFilter + "%' OR add_date LIKE '%" + 
						globalFilter + "%' OR description LIKE '%" +
						globalFilter + "%')")
					if(columnFilters.length > 0){
						filterString = filterString.concat(" AND ")
					}
				}
				if(columnFilters.length > 0){
					
					filterString = filterString.concat("(")
					columnFilters.map((col, index) => {
						
						let searchParam = " LIKE '%" + col.value + "%'"
						if(col.id === "add_date"){
							console.log(col)
							let dateFilter = ""
							if(col.value[0]){							
								dateFilter = dateFilter.concat("add_date >= '" + newDate(col.value[0].$d) + "'")
							}
							if(col.value[0] && col.value[1]){
								dateFilter = dateFilter.concat(" AND ")
							}
							if(col.value[1]){
								dateFilter = dateFilter.concat("add_date <= '" + newDate(col.value[1].$d) + "'")
							}
							if(!col.value[0] && !col.value[1]){
								dateFilter = dateFilter.concat("1=1")
							}
							filterString = filterString.concat(dateFilter)
						} else {
							if(index == 0){
								filterString = filterString.concat(col.id + searchParam)
							} else {
								filterString = filterString.concat(" AND " + col.id + searchParam)
							}
						}
					})
					filterString = filterString.concat(")")
				}
				return filterString
			}
			
      fetchURL.searchParams.set('_top', `${pagination.pageSize}`);
			if(globalFilter || columnFilters.length > 0){
				
				fetchURL.searchParams.set('_filter', filterFunction());
			}
			if(sorting.length > 0){
				fetchURL.searchParams.set('_orderby', sorting[0].id + " " + (sorting[0].desc ? "desc" : ""));
			}

      const resp = await fetch_get(fetchURL.href.split(window.location.hostname).pop())
      
			const orderIds = [];
			resp.data.map(row => {
				orderIds.push(row.id)
			})
			setOrdersList(orderIds)

      return resp;
    },
    placeholderData: keepPreviousData,
  });

	useEffect(() => {
		if(ordersList && ordersList.length > 0){
			setLoading(true)
			fetch_get("data/pay_order_items/?_filter=order_id IN (" + ordersList.join(', ') + ")")
				.then((data) =>
					setTimeout(() => {
						setOrderItems(data.data)
					}, setLoading(false)
					,2000),
				);
		}
	}, [ordersList]);

  const table = useMaterialReactTable({
    columns: tableColumns,
    data,
		enableExpandAll: false,
    initialState: { 
			showColumnFilters: false, 
			showGlobalFilter: true,
		},
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: 'error',
          children: 'Error loading data',
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
		enableStickyFooter: true,
		muiTableContainerProps: { sx: { maxHeight: window.innerHeight*0.65 } },
		enableColumnActions: false,
		enableFullScreenToggle: false,
		getRowId: (originalRow) => originalRow.user_key_id,
		localization:MRT_Localization_SV,
    rowCount: meta?.rowcount_total ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting
    },
		renderDetailPanel: ({ row }) =>
			orderItemsRender(row.original.id),
		renderTopToolbar: ({ table }) => {
			return (
        <Box
          sx={{
						padding: '16px',
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
        </Box>

      );
		},
  });

	return (
		<>
			<MDBContainer className="py-4">
				<h3>Betalningshistorik</h3>
				<hr/>
				<MDBRow>
					<MDBCol size="12" className="mb-3">
						<MaterialReactTable table={table} />
					</MDBCol>
					</MDBRow>
			</MDBContainer>
		</>
          );
}