import React, { useEffect, useState } from 'react';
import { useLocation,useNavigate } from "react-router-dom";
import {    
  MDBRow, 
  MDBCol, 
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn, 
  MDBContainer,
	MDBSpinner,
	MDBCheckbox} from 'mdb-react-ui-kit';
import { fetch_get, fetch_post } from '../../../services/common';
import jwt_decode from "jwt-decode";
import PaymentTermsModal from '../payment/PaymentTermsModal';

const LicenseType = ({data}) => {
	
  if(data.license.id == 8){
    return <MDBCardBody>
						<h5>{data.name}</h5>
            <MDBRow>
              <MDBCol md='6' className="text-start">Typ av licens</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.license.name}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Gren/diciplin</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.sport.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Åldersklass</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.age}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Giltighetsperiod</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.startDate} - {data.endDate}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Tävling</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.event.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Pris</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.price}kr</p></MDBCol>
            </MDBRow>
          </MDBCardBody>
  }
  else{
    return <MDBCardBody>
						<h5>{data.name}</h5>
            <MDBRow>
              <MDBCol md='6' className="text-start">Typ av licens</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.license.name}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Idrottskommitté</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.sport.text}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Åldersklass</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.age}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Giltighetsperiod</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.startDate} - {data.endDate}</p></MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md='6' className="text-start">Pris</MDBCol>
              <MDBCol md='6' className="text-end"><p className="text-muted mb-0">{data.price}kr</p></MDBCol>
            </MDBRow>
          </MDBCardBody>
          }
    
}

export default function ShopSummary() {

  const location = useLocation();
  const data = location.state.formValues;
	const association_id = location.state.association_id;
	const navigate = useNavigate();
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [checked,setChecked] = useState(false);
	const [showPaymentTerms, setShowPaymentTerms] = useState(false);

	useEffect(() => {
		setLoading(true)
		const data = localStorage.getItem("token");
		var decoded = "";
		if (data) {
			decoded = jwt_decode(data);
		}
		fetch_get("app/user/all/" + decoded.kid).then((data) => {
			setTimeout(() => {
				setUser(data.data)
			}, setLoading(false), 2000)
		})
	}, [])

  async function handleClickPay() {  
		const order_items = data.map(user => {
			return {        
				type: "LICENSE",
				product_id: user.license.id,
				user_id: user.userID
			}
		})
		if(user){
			const base_url = process.env.NODE_ENV === "development" ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port : window.location.protocol + "//" + window.location.hostname;
			const order = await fetch_post("app/pay/do_submit/new", {
				"action":"add_edit", 
				"db_user_id" : user.id,
				"db_operation" : "Purchase",
				"db_description" : "Licensköp",
				"db_type" : "License",
				"db_return_link" : "/association/" + association_id + "/license",
				"db_metadata" : JSON.stringify(data)});
			const payment_response = await fetch_post("app/swedbankpay/request-license/" + order.msg.key_id, {
				"url": base_url, 
				"user_agent" : window.navigator.userAgent,
				"order_items" : order_items});
			const url = payment_response.data.operations.find(({rel}) => rel === "redirect-checkout").href;
			window.open(url, "_self");
		}
  }

	function totPrice(){
		let totPrice = 0
		data.map(user => (
			totPrice = totPrice + Number(user.price)
		))
		return totPrice
	}

   return (
		<>
		{loading || !data ?     
				<MDBRow className="d-flex justify-content-center py-5">
					<MDBSpinner color='primary'>
						<span className='visually-hidden'>Loading...</span>
					</MDBSpinner>
				</MDBRow> :
    <MDBContainer className="py-4">
      <MDBRow className='d-flex justify-content-center'>
        <MDBCol size='4'>
          <MDBCard className="text-center rounded-4">
						<MDBRow className='g-0'>
								<MDBCardBody>
									<MDBCardTitle>Sammanfattning</MDBCardTitle>
								</MDBCardBody>
						</MDBRow>
						{data.map(user => (
							<span key={user.userID} className="square border">
							<LicenseType data={user}/>
							</span>
						))}
						<MDBCardBody>
							<MDBRow className='g-0'>
								<MDBCol md='6' className="text-start">Totalt: </MDBCol>
								<MDBCol md='6' className="text-end"><p className="text-muted mb-0">{totPrice()}kr</p></MDBCol>
							</MDBRow>
						</MDBCardBody>						
					</MDBCard>
					<MDBRow className='mt-3'>
						<MDBCol className='text-center'>
							<MDBCheckbox
								checked={checked}
								onChange={() => setChecked(!checked)}
							/>
							<p>Genom att markera kryssrutan godkänner jag Svenska Skyttesportsförbundets 
								<MDBBtn type='button' onClick={() => setShowPaymentTerms(!showPaymentTerms)} className='px-1 pt-0 pb-1' color='link'>
								<u>köpvillkor</u>
								</MDBBtn>
							</p>
						</MDBCol>
					</MDBRow>
					<MDBRow>
						<MDBCol md="12">
							<MDBBtn
									onClick={() => navigate(-1)}
									color='secondary'
									type="button"
									className="btn-rounded m-2"
									>
								Tillbaka
							</MDBBtn>
							<MDBBtn
									onClick={() => handleClickPay()}
									disabled={!checked}
									type="button"
									className="btn-rounded m-2 float-end"
									>
								Betala
							</MDBBtn>
						</MDBCol>
					</MDBRow>
					
        </MDBCol>
      </MDBRow>  
			<PaymentTermsModal show={showPaymentTerms} close={() => setShowPaymentTerms(false)}/>
      </MDBContainer>}
			</>
   );
}