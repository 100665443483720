import { useNavigate, useLoaderData } from "react-router-dom";
import React,{ useState, useMemo, useEffect } from 'react';
import {    
  MDBBtn,
	MDBSwitch,
	MDBCol,
	MDBRow,
	MDBContainer
 } from 'mdb-react-ui-kit';
 import {MRT_Localization_SV} from 'material-react-table/locales/sv'
import {
	MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { fetch_json, fetch_get } from "../../../services/common";
import jwt_decode from "jwt-decode";
import { BorderCol } from "../../modules/BorderCol";
import WorkInProgress from "../WorkInProgress";

export async function loader({ params }) {

	const data = localStorage.getItem("token");
	var decoded = "";
	if (data) {
		decoded = jwt_decode(data);
	}

	let user = null;
	if(params.key_id === decoded.kid){
		user = await fetch_get("app/user/all/" + params.key_id);

		let isParent = false;
		user.data.parents.map(parent => {
			const loggedInParent = localStorage.getItem("key_id")
			if(parent.key_id == loggedInParent){
				isParent = true
			}
		})
	
		const obj = {
			user: user,
			guest: decoded.kid == params.key_id ? false : true,
			parent: isParent
		}
		return obj
	}

	/* 

	if(!obj.parent && obj.guest){
		if(!window.location.href.endsWith("/profile")){
			return <Navigate to="/unauthorized" replace />;
		}
	} */

	return null;
	
	
}

export default function UserResults(){

  
    return (
		<>
			<MDBContainer className="py-4">
				<h3>Resultat</h3>
				<hr/>
				<WorkInProgress></WorkInProgress>
			</MDBContainer>
		</>
          );

  
}